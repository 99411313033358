import { axiosClient } from ".";

interface Polygon {
  type: string;
  coordinates: number[][][];
}

export const getFieldRegions = async (fieldId: number) => {
  const response = await axiosClient.get(`/api/v1/field_regions/`, {
    params: {
      field_id: fieldId,
    },
  });
  return response.data;
};

export const addFieldRegion = async (fieldId: number, polygon: Polygon) => {
  const response = await axiosClient.post(`/api/v1/field_regions/`, {
    field_id: fieldId,
    polygon: polygon,
  });
  return response.data;
};

export const updateFieldRegion = async (regionId: number, polygon: Polygon) => {
  const response = await axiosClient.put(`/api/v1/field_regions/${regionId}`, {
    polygon,
  });
  return response.data;
};

export const deleteFieldRegion = async (regionId: number) => {
  const response = await axiosClient.delete(
    `/api/v1/field_regions/${regionId}`
  );
  return response.data;
};
