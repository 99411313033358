import React, {
  useRef,
  FC,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import * as L from "leaflet";
import { customizeToolbarSettings } from "../utils/mapToolbarSettings";

import { MapContainer, TileLayer, FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import { Language, drawLocales } from "leaflet-draw-locales";
import {
  addFieldRegion,
  deleteFieldRegion,
  getFieldRegions,
  updateFieldRegion,
} from "../api/field_region";
import { Field } from "../store/fields/fieldsSlice";
import authorize from "../auth/sentinelHubAuth";
import {
  ApiType,
  BBox,
  CRS_EPSG4326,
  MimeTypes,
  S2L2ALayer,
} from "@sentinel-hub/sentinelhub-js";
import { toast } from "react-toastify";
import { scripts } from "../utils/scriptsData";
import { Menu, Transition } from "@headlessui/react";
import { MapCanvasOverlay } from "./MapCanvasOverlay";

customizeToolbarSettings();

const MapControl = ({ language }) => {
  const map = useMap();
  const { t } = useTranslation("translation");

  useEffect(() => {
    drawLocales(language as Language);
  }, [language, map]);

  return null;
};

interface FieldMapProps {
  field: Field;
  height?: string;
  width?: string;
  measurementType: string;
  children: ReactNode;
}

interface FieldRegion {
  id: number;
  fieldId: number;
  polygon: { type: string; coordinates: L.LatLngTuple[][] };
}

const FieldMap: FC<FieldMapProps> = ({
  field,
  height = "42rem",
  width = "100%",
  children,
  measurementType,
}) => {
  const [fieldRegions, setFieldRegions] = useState<FieldRegion[]>([]);
  const [selectedScriptName, setSelectedScriptName] =
    useState<string>("moistureIndex");
  const [isLoadings, setIsLoadings] = useState<{ [key: number]: boolean }>({});
  const { t } = useTranslation("translation");

  // overlay image urls
  const [regionOverlays, setRegionOverlays] = useState<{
    [key: number]: {
      imageUrl: string;
      latLngBounds: L.LatLngTuple[];
    };
  }>({});

  const featureGroupRef = useRef<L.FeatureGroup>(null);
  const canvasOverlaysRef = useRef<HTMLDivElement>(null);

  const { i18n } = useTranslation("translation");

  useEffect(() => {
    getFieldRegions(field.fieldId).then(setFieldRegions);
    authorize();
  }, [field.fieldId]);

  useEffect(() => {
    for (const region of fieldRegions) {
      getSatelliteImagery(region);
    }
  }, [fieldRegions, selectedScriptName]);

  const blobToDataURI = (
    blob: Blob
  ): Promise<string | ArrayBuffer | null | undefined> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result);
      };
      reader.onerror = (error) => {
        reject(new Error("Failed to convert blob to data URI: "));
      };
      reader.readAsDataURL(blob);
    });
  };

  const getSatelliteImagery = async (region: FieldRegion) => {
    const layer = new S2L2ALayer({
      evalscript: scripts[selectedScriptName],
      maxCloudCoverPercent: 80,
      // @sentinel-hub/sentinelhub-js is missing upsampling type, so we have to ignore type error
      // it inherits it from AbstractSentinelHubV3Layer, however the type is not inherited
      // this might get fixed in the future updates of the library
      // @ts-ignore
      upsampling: "BICUBIC",
    });

    const currentDate = new Date();
    const toTime = new Date(
      `${currentDate.toISOString().split("T")[0]}T00:00:00Z`
    );

    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    const coordinates = region.polygon.coordinates[0];

    for (let i = 0; i < coordinates.length; i++) {
      const [lng, lat] = coordinates[i];

      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    }

    const bbox = {
      minLng: minLng,
      minLat: minLat,
      maxLng: maxLng,
      maxLat: maxLat,
    };

    const getMapParams = {
      bbox: new BBox(
        CRS_EPSG4326,
        bbox.minLng,
        bbox.minLat,
        bbox.maxLng,
        bbox.maxLat
      ),
      fromTime: new Date("2023-08-05T00:00:00Z"),
      toTime: toTime,
      width: 512,
      height: 343.697,
      format: MimeTypes.JPEG,
      layerId: "test",
    };

    setIsLoadings((loadings) => ({ ...loadings, [region.id]: true }));

    try {
      const responseBlob = await layer.getMap(getMapParams, ApiType.PROCESSING);
      const dates = await layer.findDatesUTC(
        getMapParams.bbox,
        getMapParams.fromTime,
        getMapParams.toTime
      );

      if (responseBlob instanceof Blob && responseBlob.type === "image/jpeg") {
        const dataURI = await blobToDataURI(responseBlob);
        if (typeof dataURI === "string") {
          setRegionOverlays((overlays) => ({
            ...overlays,
            [region.id]: {
              imageUrl: dataURI,
              latLngBounds: coordinates.map(([lng, lat]) => [lat, lng]),
            },
          }));
        }
      } else {
        console.warn("Unexpected response format:", responseBlob);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      toast.error(
        "There was a problem fetching the image. Please try again later."
      );
    } finally {
      setIsLoadings((loadings) => ({ ...loadings, [region.id]: false }));
    }
  };

  useEffect(() => {
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();

      fieldRegions.forEach((region) => {
        const polygon = L.polygon(
          convertCoordinates(region.polygon.coordinates),
          {
            // Border style
            color: "#500073",
            weight: 2.5, // Border width

            // Interactive states
            fillRule: "evenodd",
            dashArray: "", // Solid line
            lineCap: "round",
            lineJoin: "round",

            // Interactive behavior
            interactive: true,
            bubblingMouseEvents: true,
          }
        );
        polygon.feature = {
          type: "Feature",
          properties: {
            region_id: region.id,
          },
          geometry: polygon.toGeoJSON().geometry,
        };

        featureGroupRef.current?.addLayer(polygon);
      });
    }
  }, [fieldRegions]);

  const convertLayerToGeoJSON = (layer: L.Layer): GeoJSON.Polygon => {
    if (layer instanceof L.Polygon) {
      const coordinates = layer.getLatLngs()[0];

      // Convert LatLng array to coordinate pairs and ensure polygon is closed
      const coordinateList = (coordinates as L.LatLng[]).map((coord) => [
        coord.lng, // GeoJSON uses [longitude, latitude] order
        coord.lat,
      ]);

      // Close the polygon by adding first point at end if needed
      if (
        coordinateList[0][0] !== coordinateList[coordinateList.length - 1][0] ||
        coordinateList[0][1] !== coordinateList[coordinateList.length - 1][1]
      ) {
        coordinateList.push(coordinateList[0]);
      }

      return {
        type: "Polygon",
        coordinates: [coordinateList], // Wrap in array for polygon format
      };
    }
    throw new Error("Layer must be a polygon");
  };

  const handleAreaCreated = async (e) => {
    const { layer } = e;

    try {
      const response = await addFieldRegion(
        field.fieldId,
        convertLayerToGeoJSON(layer)
      );
      setFieldRegions((regions) => [...regions, response]);

      if (featureGroupRef.current) {
        featureGroupRef.current.removeLayer(layer);
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const handleEdited = async (e) => {
    const layers = e.layers.getLayers();
    if (!layers.length) return;

    const updatedRegions: { [key: number]: FieldRegion } = {};
    for (const layer of layers) {
      // const regionId = layer.options.id;
      const regionId = (layer as L.Polygon).feature?.properties?.region_id;

      const coordinates = (layer.getLatLngs()[0] as L.LatLng[]).map(
        ({ lat, lng }: L.LatLng) => [lng, lat]
      );

      const polygon = {
        type: "Polygon",
        coordinates: [coordinates],
      };

      try {
        const updated = await updateFieldRegion(regionId, polygon);
        updatedRegions[regionId] = updated;
      } catch (err) {
        console.error("Failed to update region:", err);
      }
    }

    // Refresh regions with updated regions
    setFieldRegions((regions) => regions.map((r) => updatedRegions[r.id] || r));
  };

  const handleDeleted = async (e) => {
    const layers = e.layers.getLayers();
    if (!layers.length) return;

    const polyIds: number[] = [];
    for (const layer of layers) {
      try {
        const polygonId = (layer as L.Polygon).feature?.properties?.region_id;

        if (!polygonId) {
          console.error("No polygon ID found");
          return;
        }

        await deleteFieldRegion(polygonId);
        polyIds.push(polygonId);
      } catch (err) {
        console.error("Failed to delete region:", err);
      }
    }

    setFieldRegions((regions) =>
      regions.filter((r) => !polyIds.includes(r.id))
    );
    setRegionOverlays((overlays) => {
      const newOverlays = { ...overlays };
      polyIds.forEach((id) => delete newOverlays[id]);
      return newOverlays;
    });
  };

  const convertCoordinates = (
    coordinates: L.LatLngTuple[][]
  ): L.LatLngTuple[] => {
    // GeoJSON uses [longitude, latitude]
    // Leaflet uses [latitude, longitude]
    return coordinates[0].map((coord) => [coord[1], coord[0]]);
  };

  const handleEditStart = () => {
    if (canvasOverlaysRef.current) {
      canvasOverlaysRef.current.style.display = "none";
    }
  };

  const handleEditEnd = () => {
    if (canvasOverlaysRef.current) {
      canvasOverlaysRef.current.style.display = "block";
    }
  };

  return (
    <div>
      <div>
        <div className="flex flex-col items-end ml-auto mr-4 min-h-[60px]">
          <Menu
            as="div"
            className="relative inline-block text-left z-50 min-w-[150px]"
          >
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-amber-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-amber-100 hover:bg-amber-200">
                    <div>{t(`scriptNames.${selectedScriptName}`)}</div>

                    <svg
                      className={`-mr-1 h-5 w-5 text-gray-900 ${
                        open ? "rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-50 mt-2 w-full  origin-top-right rounded-md bg-amber-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {Object.keys(scripts).map((scriptName) => (
                        <Menu.Item key={scriptName}>
                          <button
                            className="block w-full px-4 py-2 text-left text-sm hover:bg-amber-200 text-gray-900"
                            onClick={() => setSelectedScriptName(scriptName)}
                          >
                            {t(`scriptNames.${scriptName}`)}
                          </button>
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>

          {/* <p className="text-sm">
            {t("mapDetails.imageDate")}{" "}
            {dates && (
              <span className="text-gray-500">{dateFormatter(dates)}</span>
            )}
          </p> */}
        </div>
      </div>
      <MapContainer
        key={i18n.language}
        className="z-10"
        center={{
          lat: field.lat,
          lng: field.lng,
        }}
        zoom={15}
        zoomControl={true}
        scrollWheelZoom={true}
        dragging={true}
        keyboard={true}
        style={{ height: height, width: width }}
        preferCanvas={true}
      >
        <MapControl language={i18n.language} />
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          maxZoom={19}
          minZoom={15}
        />
        {children}
        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={handleAreaCreated}
            onEdited={handleEdited}
            onDeleted={handleDeleted}
            onEditStart={handleEditStart}
            onEditStop={handleEditEnd}
            draw={{
              rectangle: true,
              polyline: false,
              circle: false,
              marker: false,
              polygon: true,
              circlemarker: false,
            }}
            edit={{
              edit: {
                selectedPathOptions: {
                  maintainColor: true,
                  moveMarkers: false, // Disable extra marker points
                },
              },
              // hiding remove toolbar options from remove button in leaflet.scss
              remove: true,
              poly: {
                allowIntersection: false,
              },
            }}
          />
        </FeatureGroup>
        <div ref={canvasOverlaysRef}>
          {Object.entries(regionOverlays).map(
            ([regionId, { imageUrl, latLngBounds }]) => (
              <MapCanvasOverlay
                key={regionId}
                imageData={imageUrl}
                bounds={latLngBounds}
                isLoading={isLoadings[parseInt(regionId)] ?? false}
              />
            )
          )}
        </div>
      </MapContainer>
    </div>
  );
};

export default FieldMap;
