const es = {
  translation: {
    common: {
      sonda: "Sonda",
      moisture: "Humedad",
      temperature: "Temperatura",
      nitrate: "Nitrato",
      phosphate: "Fosfato",
      ph: "pH",
      ec: "EC",
      errorMessage: "Hubo un error",
      generalError: "Se produjo un error desconocido",
      footer: "Estamos para servirle. Contáctenos: info@nerite.tech",
      noData: "Sin datos",
    },
    navBar: {
      myFields: "Ver Campos",
      myAccount: "Mi Cuenta",
      about: "Nosotros",
      termsOfService: "Términos y Condiciones",
      logOut: "Cerrar sesión",
      openMainMenu: "Menú principal",
    },
    logInPage: {
      logIn: "Listo",
      email: "Email*",
      password: "Contraseña*",
    },
    startMonitoringPage: {
      startMonitoringTitle: "Empezar a monitorear",
      startMonitoringText:
        "Agrega tu primer Campo, conecta tu Sonda y comienza a obtener medidas de suelo en tiempo real",
      startMonitoringButton: "Agregar Campo",
    },
    addFieldPage: {
      addFieldTitle: "Agregar Campo",
      fieldName: "Nombre del Campo*",
      cancelButton: "Cancelar",
      saveButton: "Guardar Campo",
    },
    newSondaPage: {
      newSondaTitle: "Ingresa el código de activación",
      newSondaSubTitle:
        "Conecta Sondas a tus Campos para comenzar el monitoreo en tiempo real.",
      newSondaText:
        "Debes poner la Sonda en el Campo antes de ingresar el código. Encuentra el código de activación en el paquete de su Sonda, o en el correo de confirmación enviado a tu email.",
      newSondaLaterButton: "Más tarde",
      newSondaConnectButton: "Conectar",
      saveFieldSuccessAlert:
        "Se agregó tu Campo. Conéctalo a una Sonda para empezar el monitoreo.",
    },
    addSondaPage: {
      addSondaTitle: "Ubicar tu Sonda en el Campo",
      cancelButton: "Cancelar",
      assignSondaButton: "Asignar ubicación",
      sondaError:
        "No pudimos localizar tu Sonda. Vuelve a intentarlo o manda un correo a info@nerite.tech",
    },
    fieldListPage: {
      allFields: "Todos mis campos",
      addFieldButton: "Agregar Campo",
      noMeasurements: "Aún no hay medidas.",
      recentMeasurements: "Medidas más recientes hoy a las",
      battery: "Batería",
      connection: "Intensidad de conexión",
      sondasOnFieldSectionTitle: "Sondas en este Campo",
      noSonda: "Conecta una Sonda para comenzar el monitoreo en tiempo real.",
      deleteFieldSuccessAlert: "El Campo ha sido eliminado.",
      deleteSondaSuccessAlert: "La Sonda ha sido eliminada",
      deleteSondaModalContent: "¿Eliminar Sonda?",
      deleteFieldModalContent: "¿Eliminar Campo?",
      deleteSonda: "Eliminar Sonda.",
      deleteField: "Eliminar Campo.",
      closeModal: "Cerrar",
      addSondaButton: "Agregar Sonda",
      fieldDetailsButton: "Ver detalles",
      saveSondaSuccessAlert: "Nueva Sonda agregada",
    },
    fieldDetailsPage: {
      backLink: "Regresar a Mis Campos",
      selectMeasurementType: "Seleccione el tipo de medición",
      optimalRange: "Alcance óptimo",
      saveOptimalRange: "Guardar",
    },
    imgAlt: {
      close: "Cerrar ícono",
      tickBox: "Ícono de casilla de verificación",
    },
    mapDetails: {
      markArea: "Marcar la zona para los datos por satélite.",
      usePanel: "Utiliza el panel de la esquina superior derecha del mapa.",
      loading: "Cargando...",
      downloadingData: "Descargando datos más recientes...",
      imageDate: "Fecha de la imagen:",
      zoomInText: "Ampliar",
      zoomOutText: "Alejar",
    },
    optimalRangeErrors: {
      moistureNotInRange:
        "El rango óptimo es incorrecto. El rango apropiado para humedad debe ser entre 0 y 100%.",
      temperatureNotInRange:
        "El rango óptimo es incorrecto. La temperatura mínima no puede ser menor a -273.15 °C.",
      nitrateNotInRange:
        "El rango óptimo es incorrecto. El valor mínimo de nitrato debe ser mayor o igual a 0.",
      phosphateNotInRange:
        "El rango óptimo es incorrecto. El valor mínimo de fosfato debe ser mayor o igual a 0.",
      phNotInRange:
        "El rango óptimo es incorrecto. El rango apropiado de pH es de 0 a 14.",
      ecNotInRange:
        "El rango óptimo es incorrecto. El valor mínimo de conductividad eléctrica debe ser mayor a 0.",
      minHigherThanMax: "El valor mínimo no puede ser mayor al valor máximo.",
    },
    scriptNames: {
      moistureIndex: "Índice de Humedad",
      ndvi: "NDVI",
      trueColor: "Color Verdadero",
    },
    sondaErrors: {
      lowBattery: "Batería baja",
      soilSamplingError: "Error de muestreo",
      timeSynchronizationError: "Error al sincronizar tiempo",
      locationSynchronizationError: "Error al sincronizar ubicación",
      nutrientSensorError: "Error de sensor de nutrientes",
      pneumaticSystemError: "Error del sistema neumático",
      soilTemperatureSensorError: "Error de temperatura externo",
      solutionTemperatureSensorError: "Error de temperatura de muestra",
      caseHumidityTooHigh: "Humedad interna demasiado alta",
      caseTemperatureTooHigh: "Temperatura interna demasiado alta",
      caseTemperatureTooLow: "Temperatura interna demasiado baja",
      softwareError: "Error de software",
      soilTooDryForSampling: "Humedad demasiado baja",
      soilSolutionVolumeInsufficient:
        "Volumen de solución de suelo insuficiente.",
      conductivityCalibrationMissing: "Calibración de conductividad no encontrada",
      memoryCardNotFound: "Tarjeta de memoria no encontrada",
      nitratePredictionError: "Error de predicción de nitrato",
      nitrateCalibrationMissing: "Calibración de nitrato no encontrada",
      softwareUpdateAvailable: "Actualización de software disponible",
    },
    updateSondaMessages: {
      sondaNameCanNotBeEmpty: "El nombre de la sonda no puede estar vacío",
      couldNotUpdateName: "No se pudo actualizar el nombre de la sonda",
      sondaNameUpdatedSuccessfully:
        "El nombre de la sonda se actualizó correctamente",
    },
  },
};

export default es;
