import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface SondaWithCodeProps {
  name: string;
  sondaCode: string;
}

const SondaWithCode: FC<SondaWithCodeProps> = ({ name, sondaCode }) => {
  return (
    <div>
      <span className="font-bold mr-2">{name}</span>
      <span className="text-gray-400">#{sondaCode}</span>
    </div>
  );
};

export default SondaWithCode;
