import moment from "moment";

export const customTooltip = ({ series, seriesIndex, dataPointIndex, w }) => {
  const xValue = w.globals.seriesX[seriesIndex][dataPointIndex];
  const formattedDate = moment(xValue).format("DD MMM HH:mm");
  const colors = w.globals.colors;
  const serieLabels = w.globals.seriesNames;

  const createSeriesItem = (index: number, label: string, color: string, value: number, unit: string) => {
    return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${index};display:flex;font-size:12px;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${color};"></span>
              <div class="apexcharts-tooltip-text">
                <div class="apexcharts-tooltip-y-group">
                  <span class="apexcharts-tooltip-text-y-label">${label}:</span>
                  <span class="apexcharts-tooltip-text-y-value">${value} ${unit}</span>
                </div>
              </div>
            </div>`;
  };

  let serieItems = w.globals.series
    .map((s, index) => {
      const dataPointIndex = w.globals.seriesX[index].indexOf(xValue);
      if (dataPointIndex === -1) return "";

      let value = Number.parseFloat(series[index][dataPointIndex]);
      if (Number.isNaN(value)) {
        return ""
      }

      let unit = w.globals.initialSeries[0].unit;
      let serieLabel = serieLabels[index];
      let serieColor = colors[index];

      return createSeriesItem(index, serieLabel, serieColor, value, unit);
    })
    .join("");

  let serieTitle = `<div style="padding:4px;margin-bottom:6px;background:#eceff1;border-bottom:1px solid #ddd;font-size:12px;">
                     ${formattedDate}
                    </div>`;

  return serieItems ? serieTitle + serieItems : null;
};
